import React, { Component } from "react"
import CountUp from "react-countup"
import VisibilitySensor from "react-visibility-sensor"
import ClientStripe from "../assets/images/circle-stripe.png"
import aboutImage1 from "../assets/images/about-2-1.jpg"
import aboutImage2 from "../assets/images/about-2-2.jpg"
import Img from "gatsby-image"

class AboutRoltecaDescription extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startCounter: false
    }
  }

  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ startCounter: true })
    }
  }

  render() {
    return (
      <section className="about-one ">
        <img src={ClientStripe} className="about-one__circle" alt="" />
        <div className="container text-center">
          <div className="block-title text-center">
            <h2 className="block-title__title">
              Vamos a hacer cosas <br />
              increíbles
            </h2>
          </div>
          <div className="about-one__img">
            <div className="row">
              <div className="col-lg-6">
                <Img fluid={this.props.datos.imageLeft.sharp.fluid} alt={this.props.datos.titleLeft} />
              </div>
              <div className="col-lg-6">
                <Img fluid={this.props.datos.imageRight.sharp.fluid} alt={this.props.datos.titleRight} />
              </div>
            </div>
            <div className="about-one__review">
              <p className="about-one__review-count counter">
                <VisibilitySensor
                  onChange={this.onVisibilityChange}
                  offset={{ top: 10 }}
                  delayedCall
                >
                  <CountUp end={this.state.startCounter ? 4512 : 0} />
                </VisibilitySensor>
              </p>
              <p className="about-one__review-text">recursos</p>
            </div>
          </div>
          <span className="about-one__text">
            <div className="row">
              <div className="col-lg-6">
                <h3> {this.props.datos.titleLeft} </h3>
                <p className="text-justify">{this.props.datos.textLeft}</p>
              </div>
              <div className="col-lg-6">
                <h3> {this.props.datos.titleRight} </h3>
                <p className="text-justify">{this.props.datos.textRight}</p>
              </div>
            </div>
          </span>
        </div>
      </section>
    )
  }
}

export default AboutRoltecaDescription
