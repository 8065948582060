import React from 'react';

const Faq = () => {
  return (
    <section className="faq-one">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-lg-6">
            <div className="faq-one__single">
              <div className="faq-one__icon">
                <span>?</span>
              </div>
              <div className="faq-one__content">
                <h2 className="faq-one__title">¿Cobramos?</h2>
                <p className="faq-one__text">No, el objetivo de Rolteca es publicar de forma gratuita software que pueda ayudar a la comunidad en su hobbie, y para ello todos nuestros proyectos son de uso libre </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="faq-one__single">
              <div className="faq-one__icon">
                <span>?</span>
              </div>
              <div className="faq-one__content">
                <h2 className="faq-one__title">¿De dónde sacáis el dinero?</h2>
                <p className="faq-one__text">La fuente principal de ingresos que tiene rolteca es adsense (esos anuncios que van apareciendo mientras navegas) y lo que se ingresa se reinvierte en la plataforma y nuevos proyectos</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="faq-one__single">
              <div className="faq-one__icon">
                <span>?</span>
              </div>
              <div className="faq-one__content">
                <h2 className="faq-one__title">¿Tenéis contenido pirata? </h2>
                <p className="faq-one__text">En Rolteca respetamos la propiedad intelectual de los escritores e ilustradores, por ello no mantenemos publicado ningún recurso que vaya en contra de este principio. En caso de haber publicado algún elemento por error o haberlo referenciado mal podéis hacérnoslo saber en el formulario de contacto y lo corregiremos inmediatamente </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="faq-one__single">
              <div className="faq-one__icon">
                <span>?</span>
              </div>
              <div className="faq-one__content">
                <h2 className="faq-one__title">¿Hay algún criterio para enviar un proyecto?</h2>
                <p className="faq-one__text">No, cómo sabrás este hobbie es tan diverso que es imposible saber de todo, si nos envías tu proyecto haremos un “estudio de mercado” para ver las implicaciones que tendría su desarrollo y si este compensa para la mejora que ofrece a la comunidad. </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6" style={{paddingBottom: `0px`, borderBottom: `0px none`}}>
            <div className="faq-one__single">
              <div className="faq-one__icon">
                <span>?</span>
              </div>
              <div className="faq-one__content">
                <h2 className="faq-one__title">¿Qué pasa si aceptáis mi proyecto? </h2>
                <p className="faq-one__text">En caso de aceptar tu proyecto, vas a ser participe completamente de las decisiones que se tomen durante su desarrollo o diseño, manteniendo siempre la coautoría de este. A fin de cuentas, la idea a sido tuya y te lo agradecemos</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6" style={{paddingBottom: `0px`, borderBottom: `0px none`}}>
            <div className="faq-one__single">
              <div className="faq-one__icon">
                <span>?</span>
              </div>
              <div className="faq-one__content">
                <h2 className="faq-one__title">¿Me puedo unir a Rolteca?</h2>
                <p className="faq-one__text">
                  ¡Claro!, tanto si eres desarrollador, ilustrador, escritor o quieres ayudarnos a testear las herramientas antes de lanzarlas. ¡Seguro que te encontramos un hueco! Cuanta más gente seamos mejores cosas podremos publicar</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
