import { graphql, useStaticQuery } from "gatsby"

const useNosotros = () => {

  const response = useStaticQuery(
    graphql`
        query useNosotros {
            strapiAboutUsPage {
                TextLeft
                TextRight
                TitleLeft
                TitleRight
                ImageLeft {
                    sharp: childImageSharp {
                        fluid( maxWidth: 570 ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                ImageRight {
                    sharp: childImageSharp {
                        fluid( maxWidth: 570 ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    `
  )
  return {
    textLeft: response.strapiAboutUsPage.TextLeft,
    textRight: response.strapiAboutUsPage.TextRight,
    titleLeft: response.strapiAboutUsPage.TitleLeft,
    titleRight: response.strapiAboutUsPage.TitleRight,
    imageLeft: response.strapiAboutUsPage.ImageLeft,
    imageRight: response.strapiAboutUsPage.ImageRight,
  };
}

export default useNosotros;
