import React from "react"

import Layout from "../components/layout"
import Nav from "../components/Nav"
import Footer from "../components/Footer"

import PageHeader from "../components/Header"
import AboutRoltecaDescription from "../components/AboutRoltecaDescription"
import userNosotros from "../Hooks/userNosotros"
import TeamRolteca from "../components/TeamRolteca"
import Faq from "../components/FAQ"

const AboutUsPage = () => {
  const nosotrosData = userNosotros()
  return (
    <Layout
      title="Rolteca | Nosotros"
      description="Somos gente normal que quiere aportar su granito de arena a esta comunidad, ¿quieres saber más? "
      slug={"/nosotros"}>
      <Nav />
      <PageHeader title="Nosotros" />
      <AboutRoltecaDescription
        datos={nosotrosData} />
      <TeamRolteca />
      <Faq/>
      <Footer />
    </Layout>
  )
}

export default AboutUsPage
